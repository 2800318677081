import * as React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { StyledLink, StyledA } from "./common";
import getStarted from "../images/get-started-bg.png";

export const Container = styled.div`
  display: grid;
  grid-template-columns: 60%;
  justify-content: center;
  margin-top: 40px;
  color: #2f394e;
  @media (max-width: 767px) {
    grid-template-columns: 90%;
    padding: 0 20px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    margin: 30px 0;
  }
`;
export const ContainerBlog = styled.div`
  display: grid;
  grid-template-columns: 20% 57% 10%;
  grid-column-gap: 20px;
  justify-content: center;
  margin-bottom: 50px;
  color: #2f394e;
  @media (max-width: 960px) {
    grid-template-columns: 24% 62% 10%;
    padding: 0 20px;
  }
  @media (max-width: 767px) {
    grid-template-columns: 90%;
    grid-row-gap: 15px;
  }
  @media (max-width: 500px) {
    grid-template-columns: 1fr;
  }
`;
export const ThumbnailContainer = styled.div`
  box-sizing: border-box;
  display: grid;
  grid-template-columns: 1fr;
  @media (max-width: 500px) {
    margin: 30px 0;
  }
`;
export const TextBlog = styled.p`
  margin: 13px 0 8px;
`;
export const List = styled.li`
  margin: 12px 0;
  line-height: 1.6;
`;
export const BlogPostContainer = styled.div`
  display: grid;
  grid-template-columns: 680px;
  justify-content: center;
  padding: 20px 40px;
  margin-top: ${(props) => (props.marginTop ? `65px` : `0`)};
  margin-bottom: ${({ marginBottom }) => marginBottom && marginBottom};
  @media (max-width: 800px) {
    grid-template-columns: 1fr;
  }
  @media (max-width: 500px) {
    padding: 20px;
  }
`;
export const BlogLink = styled(StyledLink)`
  color: #635bff;
`;
export const BlogA = styled(StyledA)`
  color: #635bff;
`;

export const CategoryContainer = styled.div`
  display: flex;
  flex-flow: row;
  color: rgba(47, 57, 78, 0.6);
  font-size: 17px;
  @media (max-width: 1450px) {
    font-size: 15px;
  }
`;
export const Date = styled.p`
  margin: 0;
  padding-top: 6px;
  color: rgba(47, 57, 78, 0.6);
  font-size: 17px;
  @media (max-width: 1450px) {
    font-size: 15px;
  }
`;
export const Title = styled.h1`
  font-size: 34px;
  margin: 15px 0;
  /* @media (max-width: 500px) {
    font-size: 34px;
  } */
`;
export const Description = styled.h2`
  font-weight: 400;
  font-size: 25px;
  margin: 5px 0;
  color: rgba(47, 57, 78, 0.6);
  @media (max-width: 1450px) {
    font-size: 23px;
  }
`;
export const Author = styled.div`
  margin: 20px 0 50px 0;
  font-size: 17px;
  @media (max-width: 1450px) {
    font-size: 15px;
  }
`;
export const RelatedArticleWholeContainer = styled.div`
  padding: 15px 8% 40px 8%;
  margin-top: 50px;
  background-color: #f6f6f6;
`;
export const RelatedArticleContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 30px;
  justify-content: start;
  align-items: start;
  padding-top: 30px;
  border-top: 1px solid rgba(22, 31, 50, 0.1);
  @media (max-width: 1024px) {
    grid-template-columns: 35% 35%;
    justify-content: center;
  }
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (max-width: 500px) {
    grid-template-columns: 250px;
  }
`;
export const RelatedArticleTitle = styled.p`
  color: #2f394e;
  font-weight: 500;
  font-size: 22px;
  @media (max-width: 500px) {
    text-align: center;
  }
`;
export const RelatedArticleItem = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  align-content: center;
`;
export const RelatedArticleInfo = styled.div`
  padding-top: 15px;
`;

export const AuthorContainer = styled.div`
  display: grid;
  grid-template-columns: 50px auto;
  align-content: center;
  grid-column-gap: 25px;
`;
const AuthorContainerInnerDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const TableOfContents = styled.div`
  position: sticky;
  top: 30px;
  padding-left: 15px;
  /* border-left: 4px solid #d4f3ee; */
  margin-top: 90px;
  @media (max-width: 960px) {
    margin-top: 70px;
  }
`;
const TableOfContentsInfo = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  /* padding-left: 15px; */
  /* border-left: 4px solid #d4f3ee; */
  max-height: 85vh;
  overflow: auto;
  @media (max-width: 767px) {
    max-height: max-content;
  }
`;
const TableOfContentsTitle = styled.p`
  margin: 0;
  padding: 3px 0 10px 0;
  color: #161f32;
  font-size: 22px;
`;
const TableOfContentsItem = styled.div`
  padding: 3px 5px;
  margin: 3px 0;
`;
const TableOfContentsLink = styled.a`
  color: rgba(47, 57, 78, 0.7);
  font-size: 15px;
`;
const TableOfContentsLinkSelect = styled.a`
  color: #20bead;
  font-weight: 500;
  font-size: 15px;
`;
const ShareArticleContainer = styled.div`
  position: sticky;
  top: 30px;
  display: grid;
  grid-template-rows: auto;
  margin-top: 90px;
  @media (max-width: 960px) {
    margin-top: 70px;
  }
  @media (max-width: 767px) {
    margin-top: 15px;
    padding: 0 20px;
  }
  @media (max-width: 500px) {
    padding: 0;
  }
`;
export const ArticleText = styled.p`
  color: rgba(47, 57, 78, 0.7);
  font-size: 15px;
`;
export const BlogButtonAnchor = styled(StyledA)`
  margin: 1.5em auto;
`;
//all blogs page
export const BlogPoster = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  align-items: center;
  padding: 40px 3em;
  background-image: url(${getStarted});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: #fff;
`;
export const BlogsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(280px, 340px));
  gap: 20px;
  padding: 4em 1em;
  justify-content: center;
  @media (max-width: 1023px) {
    grid-template-columns: repeat(auto-fit, minmax(280px, 340px));
  }
`;
export const BlogCard = styled.div`
  display: grid;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 20px;
  box-shadow: 0px 20px 40px #94a6ce1a;
  height: 100%;
  border: 1px solid #FFFFFF;
  &:hover {
    border: 1px solid #635BFF;
  }
`;
